<!--
 * @Description: 上传视频
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-18 22:13:18
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/sourceMaterial/components/videoAdd.vue
-->
<template>
  <div class="videos-popup" @click="shows = false">
    <div class="videos" @click.stop>
      <div class="videos-text">选择要上传的视频</div>
      <div class="videos-content" v-if="!video" @click="handleSelectVideos">
        <div class="item-image">
          <img src="../../../../../assets/icon/upload.png" alt="" />
        </div>
      </div>
      <div class="videos-content" v-else>
        <video controls>
          <source :src="video.content" type="video/mp4" />
        </video>
        <div class="content-progress" v-if="percentageState">
          <el-progress type="circle" :percentage="percentage"></el-progress>
        </div>
        <!-- <div class="content-img">
          <img src="../../../../../assets/icon/play.png" alt="" srcset="" />
        </div> -->
      </div>
      <div class="videos-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="videos-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { uploadImage, getToken } from "../../../../../utils/common";
// eslint-disable-next-line no-unused-vars
import { multipartUpload } from "../../../../../utils/oss";
import { HTTP } from "../../../../../utils/request";
import httpConfig from "../../../../../utils/index";

const URL = {
  checkVideo: "api/customer/userMaterial/checkVideo",
  uploadVideo: "api/customer/userMaterial/uploadVideo",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      video: null,
      percentage: 0,
      percentageState: false,
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async checkVideo(file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("scene", "add");
        const url = `${httpConfig.URL_PREFIX_API[1]}${URL.checkVideo}`;
        const resp = await fetch(url, {
          method: "post",
          headers: {
            token: getToken(),
          },
          body: formData,
        });
        const res = await resp.json();
        return res?.data;
      } catch (e) {
        console.log(e);
      }
    },

    async handleSelectVideos() {
      const files = await uploadImage(["mp4, 'png'"]);
      const reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = async () => {
        this.video = {
          content: reader.result,
          file: files,
        };
      };
    },

    async uploadVideo(objectName) {
      await HTTP({
        url: URL.uploadVideo,
        data: {
          objectName,
        },
      });
    },

    async handleConfirm() {
      if (this.percentageState) return;
      if (!this.video) {
        this.$message.error("请上传视频");
        return;
      }
      const { objectName } = await this.checkVideo(this.video.file);
      if (objectName) {
        this.percentageState = true;
        multipartUpload(this.video, objectName, async (proportion) => {
          this.percentage = proportion * 100;
          if (proportion === 1) {
            await this.uploadVideo(objectName);
            this.shows = false;
            this.$message.success("上传成功");
            this.$emit("update");
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.videos-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .videos {
    width: 427px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      justify-content: center;
      margin: auto {
        top: 25px;
      }
      width: 194px;
      height: 341px;
      background-color: #eee;
      position: relative;
      overflow: hidden;
      video {
        width: 100%;
        height: 100%;
      }
      .content-progress {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #fff, $alpha: 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .item-image {
        width: 100%;
        height: 100%;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        img {
          width: 40px;
          height: 40px;
        }
      }
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 37.14px;
          height: 37.14px;
          cursor: pointer;
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 16px;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
