<!--
 * @Description: 视频
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-18 21:02:24
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/sourceMaterial/mods/videos.vue
-->
<template>
  <div>
    <div class="tip">
      <div class="tip-left">说明：</div>
      <div class="tip-right">
        <div>1. 视频主要以公司广告视频为主，包含公司介绍、公司产品介绍等。</div>
        <div>2. 视频格式：竖版9:16，MP4</div>
        <div>3. 视频大小：50M，时长最大30秒</div>
        <div>
          4. 可上传文件个数：·基础版-6个 ·专业版-12个 ·企业版-29个 ·旗舰版-46个
        </div>
      </div>
    </div>
    <div
      class="source-material-list"
      v-if="tableData.length"
      v-loading="isLoading"
    >
      <div
        class="source-material-item"
        v-for="item in tableData"
        :key="item.id"
      >
        <div class="item-content">
          <img :src="item.video_cover_path" alt="" />
          <div class="content-img">
            <img src="../../../../../assets/icon/play.png" alt="" srcset="" />
          </div>
        </div>
        <div class="item-remove" v-if="!product?.isDisableMaterisUploadEntry">
          <span @click="handleRemove(item)">
            <i class="el-icon-delete"></i> 删除</span
          >
        </div>
      </div>
    </div>
    <el-empty v-else description="暂无上传"></el-empty>
    <pagination
      v-show="page.total > 0"
      :total="page.total"
      :page.sync="page.page"
      :limit.sync="page.size"
      @pagination="getList()"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../../../utils/request";

const URL = {
  list: "api/customer/userMaterial/list",
  remove: "api/customer/userMaterial/remove",
};
export default {
  props: {
    product: {
      type: Object,
    },
  },
  data() {
    return {
      tableData: [],
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    async getList(page = this.page.page, size = this.page.size) {
      this.isLoading = true;
      const res = await HTTP({
        url: URL.list,
        method: "get",
        data: {
          materialType: 4,
          page,
          limit: size,
        },
      });
      let { total, per_page, current_page, data } = res || {};
      this.page = { total, page: current_page, size: +per_page };
      this.tableData = data || [];
      this.isLoading = false;
    },
    handleRemove({ id }) {
      this.$confirm("确认删除此数据吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          HTTP({
            url: URL.remove,
            method: "post",
            data: {
              materialType: 4,
              materialIds: id,
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.getList(1);
          });
        })
        .catch(() => {
          this.$message.info("已取消【删除】操作");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tip {
  display: flex;
  font-size: 12px;
  font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
  font-weight: normal;
  color: #7c7f97;
  line-height: 24px;
  margin-top: 10px;
}
.source-material-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-left: 1px solid #e4e8fc;
  margin-top: 14px;
  .source-material-item {
    width: calc(100% / 6);
    padding: 20px;
    border-right: 1px solid #e4e8fc;
    border-bottom: 1px solid #e4e8fc;
    border-top: 1px solid #e4e8fc;
    .item-content {
      width: 126px;
      height: 222px;
      background-color: #eee;
      margin: 0 auto;
      position: relative;
      & > img {
        width: 100%;
        height: 100%;
      }
      .content-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba($color: #000000, $alpha: 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 30px;
          height: 30px;
          cursor: pointer;
        }
      }
    }
    .item-remove {
      text-align: center;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
      font-weight: normal;
      color: #7c7f97;
      margin-top: 20px;
      &:hover {
        color: #4e6bef;
        cursor: pointer;
      }
    }
    &:hover {
      background-color: #f6f6fd;
    }
  }
}
</style>
