<!--
 * @Description: 上传图片
 * 
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-18 21:59:48
 * @FilePath: /JinChanTuoKe-Front/src/pages/views/content/sourceMaterial/components/imageAdd.vue
-->

<template>
  <div class="images-popup" @click="shows = false">
    <div class="images" @click.stop v-loading="isLoading">
      <div class="images-text">选择要上传的图片</div>
      <div>
        <el-dropdown
          style="margin-left: 23px; margin-top: 10px"
          @command="handleCommand"
        >
          <el-button type="primary" size="medium">
            上传图片<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="a">上传图片</el-dropdown-item>
            <el-dropdown-item command="b">上传目录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        请选择文件格式：PNG、JPG
      </div>
      <div class="images-content">
        <!-- <div class="content-item" @click="handleUploadImage">
          <div class="item-image">
            <img src="../../../../../assets/icon/upload.png" alt="" />
          </div>
        </div> -->
        <div class="content-item" v-for="(item, index) in imgs" :key="item.key">
          <img :src="item.img" alt="" />
          <div class="item-close" @click="handleRemove(item, index)">
            <i class="el-icon-error"></i>
          </div>
        </div>
      </div>
      <div class="images-btn">
        <div class="btn confirm" @click="handleConfirm">确认</div>
        <div class="btn" @click="shows = false">取消</div>
      </div>
      <div class="images-close" @click="shows = false">
        <img src="../../../../../assets/icon/close.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { uploadImage, getToken, genUuid } from "../../../../../utils/common";
import httpConfig from "../../../../../utils/index";

const URL = {
  uploadImage: "api/customer/userMaterial/uploadImage",
};

export default {
  props: {
    show: Boolean,
    data: {
      type: Object,
    },
  },
  data() {
    return {
      files: [],
      imgs: [],
    };
  },
  computed: {
    shows: {
      get() {
        return this.show;
      },
      set(newValue) {
        this.$emit("update:show", newValue);
      },
    },
  },
  methods: {
    async handleUploadImage(type) {
      const files = await uploadImage(["jpeg", "jpg", "png"], true, type);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
          const key = genUuid();
          this.files.push({
            file,
            key,
          });
          this.imgs.push({
            img: reader.result,
            key,
          });
        };
      }
    },
    handleCommand(command) {
      switch (command) {
        case "a":
          this.handleUploadImage();
          break;
        case "b":
          this.handleUploadImage("webkitdirectory");
          break;
        default:
          break;
      }
    },
    handleRemove(item, index) {
      this.imgs.splice(index, 1);
      const fileIndex = this.files.findIndex((e) => e.key === item.key);
      this.files.splice(fileIndex, 1);
    },
    async handleConfirm() {
      if (!this.files.length) {
        this.$message.error("请先上传图片");
        return;
      }
      if (this.files.length > 50) {
        this.$message.error("一次只能上传50张图片");
        return;
      }
      this.isLoading = true;
      for (let i = 0; i < this.files.length; i++) {
        const item = this.files[i];
        const res = await this.uploadImage(item.file);
        if (!res) {
          this.isLoading = false;
          break;
        } else {
          if (i === this.files.length - 1) {
            this.isLoading = false;
            this.shows = false;
            this.$message.success("上传成功");
            this.$emit("update");
          }
        }
      }
    },

    async uploadImage(file) {
      const formData = new FormData();
      formData.append("file", file);
      const url = `${httpConfig.URL_PREFIX_API[1]}${URL.uploadImage}`;
      const resp = await fetch(url, {
        method: "post",
        headers: {
          token: getToken(),
        },
        body: formData,
      });
      const res = await resp.json();
      if (res.code === 200) {
        return true;
      } else {
        this.$message.error(res.message);
        return false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.images-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color: #000000, $alpha: 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  .images {
    width: 1027px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    &-text {
      font-size: 30px;
      color: #000000;
      text-align: center;
      margin-top: 35px;
    }
    &-content {
      display: flex;
      flex-wrap: wrap;
      height: 400px;
      overflow-y: auto;
      margin-left: 23px;
      margin-top: 30px;
      .content-item {
        width: 80px;
        height: 140px;
        background-color: #eee;
        margin-right: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        & > img {
          width: 100%;
        }
        .item-close {
          width: 16px;
          height: 16px;
          position: absolute;
          z-index: 1;
          top: 0;
          right: 0;
          display: flex;
          justify-content: flex-end;
          color: #f56c6c;
          cursor: pointer;
          display: none;
        }
        &:hover .item-close {
          display: block;
        }
        .item-image {
          width: 40px;
          height: 40px;
          overflow: hidden;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      //   justify-content: center;
      //   margin: auto {
      //     top: 25px;
      //   }
      //   width: 194px;
      //   height: 341px;
      //   background-color: #eee;
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0 50px 0;
      .btn {
        width: 140px;
        height: 37px;
        background-color: #e0e4f6;
        margin-right: 16px;
        &:last-child {
          margin-right: 16px;
        }
        font-size: 16px;
        font-family: SourceHanSansCN-Regular-, SourceHanSansCN-Regular;
        font-weight: normal;
        color: #000000;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        cursor: pointer;
      }
      .confirm {
        background-color: #4e6bef;
        color: #fff;
      }
    }
    &-close {
      width: 20px;
      height: 20px;
      position: absolute;
      top: 20px;
      right: 20px;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
