/*
 * @Description: OSS
 *
 * @Author: XiongYaoYuan<xiongyaoyuan>
 * @Date: 2023-09-24 13:16:59
 * @FilePath: /JinChanTuoKe-Front/src/utils/oss.js
 */
const OSS = require("ali-oss");
// const path = require("path");
// import { File } from "@ionic-native/file";

// eslint-disable-next-line no-unused-vars
const client = new OSS({
  // yourregion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
  region: "oss-cn-hangzhou",
  // 从环境变量中获取访问凭证。运行本代码示例之前，请确保已设置环境变量OSS_ACCESS_KEY_ID和OSS_ACCESS_KEY_SECRET。
  accessKeyId: "LTAI5tHfS31nb6CvSeAWjhP7",
  accessKeySecret: "BTN6CWPtF0lwe0WPNibjuvWa9mPRSy",
  //   accessKeyId: process.env.OSS_ACCESS_KEY_ID,
  //   accessKeySecret: process.env.OSS_ACCESS_KEY_SECRET,
  // 填写存储空间名称。
  bucket: "aboostify",
});

// eslint-disable-next-line no-unused-vars
const progress = (p, _checkpoint) => {
  // Object的上传进度。
  console.log(p);
  // 分片上传的断点信息。
  console.log(_checkpoint);
};

// eslint-disable-next-line no-unused-vars
const headers = {
  // 指定Object的存储类型。
  "x-oss-storage-class": "Standard",
  // 指定Object的访问权限。
  "x-oss-object-acl": "private",
  // 通过文件URL访问文件时，指定以附件形式下载文件，下载后的文件名称定义为example.txt。
  "Content-Disposition": 'attachment; filename="example.txt"',
  // 设置Object的标签，可同时设置多个标签。
  "x-oss-tagging": "Tag1=1&Tag2=2",
  // 指定PutObject操作时是否覆盖同名目标Object。此处设置为true，表示禁止覆盖同名Object。
  "x-oss-forbid-overwrite": "true",
};

// eslint-disable-next-line no-unused-vars
const rules = [
  {
    // 指定允许跨域请求的来源，支持通配符星号（*），表示允许所有的来源域。
    allowedOrigin: "*",
    // 指定允许的跨域请求方法，支持GET、PUT、DELETE、POST和HEAD方法。
    allowedMethod: "GET",
    // 指定允许跨域请求的响应头。建议无特殊情况下将此项设置为通配符星号（*）。
    allowedHeader: "*",
    // 指定允许用户从应用程序中访问的响应头，例如一个JavaScript的XMLHttpRequest对象。不允许使用通配符星号（*）。
    exposeHeader: "*",
    // 指定浏览器对特定资源的预取（OPTIONS）请求返回结果的缓存时间，单位为秒。
    maxAgeSeconds: "10",
  },
];
const readFile = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = function (event) {
      resolve(event);
    };
  });
};
const toBlob = (urlData, fileType) => {
  let bytes = window.atob(urlData);
  let n = bytes.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bytes.charCodeAt(n);
  }
  return new Blob([u8arr], { type: fileType });
};

// 开始分片上传
export const multipartUpload = (file, name, proportion) => {
  const fileN = file.content;
  //   const fileU = file.file.name;
  const base64 = fileN.split(",").pop();
  const fileType = fileN.split(";").shift().split(":").pop();
  const blob = toBlob(base64, fileType);
  readFile(blob).then((res) => {
    // const fileExtension = fileU.substring(fileU.lastIndexOf("."));
    const objectKey = name;
    const buffer = new OSS.Buffer(res.target.result);
    client
      .multipartUpload(objectKey, buffer, {
        progress: (p) => {
          // Object的上传进度。
          proportion(p);
        },
      })
      .then((result) => {
        console.log(result);
      })
      .catch((err) => {
        console.log(err);
      });
  });
};
